import {
    SupportedTenant,
    useTenant,
    useTenantSetting,
    useTenantSettings,
} from '@nestoca/multi-tenant';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import {
    getApplicationApplicantAccountMatch,
    getIsChangeApplicationActive,
    getHasSomePropertiesWithBridgeLoan,
    selectedApplication,
    currentApplicationIdState,
    getSubjectProperty,
} from 'store/applications';
import { APPLICATION_TYPE, type ApplicationType } from 'types/application';
import { getApplicationMainType } from 'utils/application-type';
import { useIsTenant } from 'utils/useIsTenant';

import { getSelectedHelocProduct } from './heloc';
import { getHasSubmissionNoteSubmitted } from './submission-notes';

const BLOCKED_STATES = [
    'NOTES_SUBMITTED',
    'LENDER_SUBMITTED',
    'LENDER_APPROVED',
    'PENDING_COMMITMENT_SIGNATURE',
    'PENDING_CONDITIONS',
    'COMPLETE',
    'NOTARY_ALERTED',
    'FUNDED',
    'CLOSED',
    'EXPIRED',
];

export const useUpdateApplicationTypeRights = () => {
    const { applicationType: applicationTypeFlag } = useFlags();

    const isChangeApplicationActive = useRecoilValue(
        getIsChangeApplicationActive
    );

    return applicationTypeFlag && isChangeApplicationActive;
};

export const useCanadaLifeImporterRights = () => {
    const { slug } = useTenant();

    const { hasCanadaLifeImporterRights: hasCanadaLifeImporterRightsFlag } =
        useFlags();

    const hasCanadaLifeImporterRights: Record<SupportedTenant, boolean> = {
        nesto: hasCanadaLifeImporterRightsFlag as boolean,
        ig: false,
        'ig-dev': false,
    };

    return hasCanadaLifeImporterRights[
        slug as keyof typeof hasCanadaLifeImporterRights
    ];
};

export const useSyncAccountDetailsRights = () => {
    const { hasSyncAccountDetailsRights } = useFlags();

    const selectedApp = useRecoilValue(selectedApplication);

    const applicationApplicantAccountMatch = useRecoilValue(
        getApplicationApplicantAccountMatch(selectedApp?.id)
    );

    return (
        hasSyncAccountDetailsRights &&
        applicationApplicantAccountMatch &&
        !applicationApplicantAccountMatch.isMatch
    );
};

export const useExportDataToServicingRights = () => {
    const { slug } = useTenant();

    const hasExportDataToServicingRights: Record<SupportedTenant, boolean> = {
        ig: false,
        'ig-dev': false,
        nesto: true,
    };

    return hasExportDataToServicingRights[
        slug as keyof typeof hasExportDataToServicingRights
    ];
};

export const usePreApprovalLetterRights = () => {
    const { slug } = useTenant();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasSubmissionNoteSubmitted = useRecoilValue(
        getHasSubmissionNoteSubmitted(selectedApp?.id)
    );

    const hasPreApprovalLetterRights: Record<SupportedTenant, boolean> = {
        ig: hasSubmissionNoteSubmitted,
        'ig-dev': hasSubmissionNoteSubmitted,
        nesto: true,
    };

    const tooltipMessage: Record<SupportedTenant, string> = {
        ig: hasPreApprovalLetterRights[slug]
            ? ''
            : 'preApprovalLetter.requiredToHave.submissionNoteSubmitted',
        'ig-dev': hasPreApprovalLetterRights[slug]
            ? ''
            : 'preApprovalLetter.requiredToHave.submissionNoteSubmitted',
        nesto: '',
    };

    return {
        hasPreApprovalLetterRights:
            hasPreApprovalLetterRights[
                slug as keyof typeof hasPreApprovalLetterRights
            ],
        tooltipMessage: tooltipMessage[slug as keyof typeof tooltipMessage],
    };
};

export const useLoanAgreementLetterRights = () => {
    const { showLoanAgreementDownload } = useFlags();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasLoanProduct = !!selectedApp?.product;

    const appTypeHasLoanAgreement =
        selectedApp?.type !== APPLICATION_TYPE.CHANGE_OF_COVENANT &&
        selectedApp?.type !== APPLICATION_TYPE.ASSUMPTION;

    return (
        showLoanAgreementDownload && hasLoanProduct && appTypeHasLoanAgreement
    );
};

export const useBridgeLoanLetterRights = () => {
    const { slug } = useTenant();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasSomePropertiesWithBridgeLoan = useRecoilValue(
        getHasSomePropertiesWithBridgeLoan(selectedApp?.id)
    );

    const hasBridgeLoanLetterRights: Record<SupportedTenant, boolean> = {
        ig: hasSomePropertiesWithBridgeLoan,
        'ig-dev': hasSomePropertiesWithBridgeLoan,
        nesto: false,
    };

    return hasBridgeLoanLetterRights[
        slug as keyof typeof hasBridgeLoanLetterRights
    ];
};

export const useHelocLoanAgreementLetterRights = () => {
    const hasHelocRights = useHasHelocRights();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasHelocProduct = !!selectedApp?.heloc?.helocAmount;

    return hasHelocRights && hasHelocProduct;
};

export const useUnsignedCommitmentLetterRights = () => {
    const { slug } = useTenant();

    const selectedApp = useRecoilValue(selectedApplication);

    const hasProduct = !!selectedApp?.product;

    const hasUnsignedCommitmentLetterRights: Record<SupportedTenant, boolean> =
        {
            ig: false,
            'ig-dev': false,
            nesto: hasProduct,
        };

    return hasUnsignedCommitmentLetterRights[
        slug as keyof typeof hasUnsignedCommitmentLetterRights
    ];
};

export const useNoticeOfCreditChargesRights = () => {
    const {
        value: enableHeloc,
        settings: { noticeOfCreditCharges },
    } = useTenantSetting('enableHeloc');
    const applicationId = useRecoilValue(currentApplicationIdState);
    const subjectProperty = useRecoilValue(getSubjectProperty(applicationId));
    const selectedHelocProduct = useRecoilValue(
        getSelectedHelocProduct(applicationId)
    );

    const noticeOfCreditChargesEnabled = enableHeloc && noticeOfCreditCharges;
    const hasHelocProduct = !!selectedHelocProduct;
    const isQC = subjectProperty?.address?.stateCode === 'QC';

    // Notice of Credit Charges is only available for QC and HELOC products
    return noticeOfCreditChargesEnabled && isQC && hasHelocProduct;
};

export const useSubmissionNotesCreationRights = () => {
    const { hasProcessorRights } = useFlags();

    return !hasProcessorRights;
};

export const usePlaidMonitoringRights = () => {
    const { showPlaidMonitoring, hasUwRights } = useFlags();

    return (showPlaidMonitoring && hasUwRights) as boolean;
};

export const useEditingRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const { hasUwRights, hasProcessorRights, deactivateRights } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    if (['CLOSED', 'EXPIRED'].includes(applicationState)) {
        return false;
    }

    //we allow a broker to edit at these steps
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
        ].includes(applicationState)
    ) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    //we allow a Processor to edit at this extra step
    if (
        [
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        hasProcessorRights
    ) {
        return true;
    }

    return false;
};

export const useCapitalMarketsRights = (): boolean => {
    const { capitalMarketRights } = useFlags();

    return capitalMarketRights;
};

export const useEditProductAfterNotesSubmittedRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const {
        hasUwRights,
        hasProcessorRights,
        hasLendingRights,
        deactivateRights,
    } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    // IF they have Lending Rights &  Processors Rights and the App is not completed
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        (hasLendingRights || hasProcessorRights)
    ) {
        return true;
    }

    // If they have no lending right we block them for these states
    if (BLOCKED_STATES.includes(applicationState)) {
        return false;
    }

    return true;
};

export const useDocumentsEditingRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const { hasUwRights, hasProcessorRights, deactivateRights } = useFlags();

    // deactivate right ==== SUPER ADMIN
    if (deactivateRights) {
        return true;
    }

    if (['CLOSED', 'EXPIRED'].includes(applicationState)) {
        return false;
    }

    //we allow a broker to edit at these steps
    if (
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
        ].includes(applicationState)
    ) {
        return true;
    }

    // UW team has edit access to all APPLICATION STATES
    if (hasUwRights) {
        return true;
    }

    //we allow a Processor to edit at this extra step
    if (
        [
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        hasProcessorRights
    ) {
        return true;
    }

    return false;
};

export const useClosingDocsRights = () => {
    const application = useRecoilValue(selectedApplication);
    const applicationState = application?.applicationState;
    const {
        hasUwRights,
        hasProcessorRights,
        hasLendingRights,
        deactivateRights,
    } = useFlags();
    const { isIGTenant } = useIsTenant();

    // we allow everyone with these flags access to the closing docs without the Application state condition
    // We will have to revert this condition as soon as SF is fixed
    if (
        hasProcessorRights ||
        hasUwRights ||
        hasLendingRights ||
        deactivateRights
    ) {
        return {
            view: true,
            edit: true,
        };
    }
    // returns true if IG user up until COMPLETE stage of APPLICATION
    if (
        isIGTenant &&
        [
            'CREATED',
            'SUBMITTED',
            'UNDER_REVISION',
            'REVIEWED',
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState)
    ) {
        return {
            view: true,
            edit: true,
        };
    }
    //we allow a broker to edit at these steps
    if (
        ['CREATED', 'SUBMITTED', 'UNDER_REVISION', 'REVIEWED'].includes(
            applicationState
        )
    ) {
        return {
            view: true,
            edit: false,
        };
    }

    //we allow Processor, UW,Funding to edit at this step
    if (
        [
            'NOTES_SUBMITTED',
            'LENDER_SUBMITTED',
            'LENDER_APPROVED',
            'PENDING_COMMITMENT_SIGNATURE',
            'PENDING_CONDITIONS',
        ].includes(applicationState) &&
        (hasProcessorRights ||
            hasUwRights ||
            hasLendingRights ||
            deactivateRights)
    ) {
        return {
            view: true,
            edit: true,
        };
    }
    //we allow Processor, UW,Funding to view at this step
    if (
        ['COMPLETE', 'NOTARY_ALERTED', 'FUNDED', 'CLOSED', 'EXPIRED'].includes(
            applicationState
        ) &&
        (hasProcessorRights ||
            hasUwRights ||
            hasLendingRights ||
            deactivateRights)
    ) {
        return {
            view: true,
            edit: false,
        };
    }

    return {
        view: false,
        edit: false,
    };
};

export const useHasHelocRights = () => {
    const {
        value: enableHeloc,
        settings: { supportsRenewalTransactions },
    } = useTenantSetting('enableHeloc');

    const application = useRecoilValue(selectedApplication);

    const { isRenewalApplication } = getApplicationMainType(application.type);

    if (!isRenewalApplication) {
        return enableHeloc;
    }

    return enableHeloc && supportsRenewalTransactions;
};

export const useShowDigitalFlag = (isDigital?: boolean) => {
    const { slug } = useTenant();

    const showDigitalFlag: Record<SupportedTenant, boolean> = {
        ig: !!isDigital,
        'ig-dev': !!isDigital,
        nesto: false,
    };

    return showDigitalFlag[slug];
};

export const useHasEnableMaturityDateOverride = () => {
    const { enableMaturityDateOverride } = useTenantSettings();
    const application = useRecoilValue(selectedApplication);

    const isNestoPort = application.type === 'NEW' && application.isPort;

    const supportedIGTransactionTypes = (
        [
            APPLICATION_TYPE.ASSUMPTION,
            APPLICATION_TYPE.CHANGE_OF_COVENANT,
            APPLICATION_TYPE.PORT,
        ] as Partial<ApplicationType>[]
    ).includes(application?.type);

    const supportedTransactionTypes =
        isNestoPort || supportedIGTransactionTypes;

    return enableMaturityDateOverride.value && supportedTransactionTypes;
};

// hasProcessorRights has edit rights for the following STATES:
// APPLICATION REVIEW -
//  'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED'

// DOCUMENTS SECTION:

// 'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED',
// | 'LENDER_APPROVED',
// | 'PENDING_COMMITMENT_SIGNATURE',
// | 'PENDING_CONDITIONS'

// hasUwRights has edit rights for the following STATES:
// APPLICATION REVIEW - & DOCUMENTS SECTION
//  'CREATED'
// | 'SUBMITTED'
// | 'UNDER_REVISION'
// | 'REVIEWED'
// | 'NOTES_SUBMITTED'
// | 'LENDER_SUBMITTED'
// | 'LENDER_SUBMITTED',
// | 'LENDER_APPROVED',
// | 'PENDING_COMMITMENT_SIGNATURE',
// | 'PENDING_CONDITIONS',
